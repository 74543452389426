import service from '@/utils/request'; // @Tags CouponThirdOrder
// @Summary 创建CouponThirdOrder
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CouponThirdOrder true "创建CouponThirdOrder"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /couponThirdOrders/createCouponThirdOrder [post]

export var createCouponThirdOrder = function createCouponThirdOrder(data) {
  return service({
    url: "/couponThirdOrders/createCouponThirdOrder",
    method: 'post',
    data: data
  });
}; // @Tags CouponThirdOrder
// @Summary 删除CouponThirdOrder
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CouponThirdOrder true "删除CouponThirdOrder"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /couponThirdOrders/deleteCouponThirdOrder [delete]

export var deleteCouponThirdOrder = function deleteCouponThirdOrder(data) {
  return service({
    url: "/couponThirdOrders/deleteCouponThirdOrder",
    method: 'delete',
    data: data
  });
}; // @Tags CouponThirdOrder
// @Summary 删除CouponThirdOrder
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除CouponThirdOrder"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /couponThirdOrders/deleteCouponThirdOrder [delete]

export var deleteCouponThirdOrderByIds = function deleteCouponThirdOrderByIds(data) {
  return service({
    url: "/couponThirdOrders/deleteCouponThirdOrderByIds",
    method: 'delete',
    data: data
  });
}; // @Tags CouponThirdOrder
// @Summary 更新CouponThirdOrder
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CouponThirdOrder true "更新CouponThirdOrder"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /couponThirdOrders/updateCouponThirdOrder [put]

export var updateCouponThirdOrder = function updateCouponThirdOrder(data) {
  return service({
    url: "/couponThirdOrders/updateCouponThirdOrder",
    method: 'put',
    data: data
  });
}; // @Tags CouponThirdOrder
// @Summary 用id查询CouponThirdOrder
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CouponThirdOrder true "用id查询CouponThirdOrder"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /couponThirdOrders/findCouponThirdOrder [get]

export var findCouponThirdOrder = function findCouponThirdOrder(params) {
  return service({
    url: "/couponThirdOrders/findCouponThirdOrder",
    method: 'get',
    params: params
  });
}; // @Tags CouponThirdOrder
// @Summary 分页获取CouponThirdOrder列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取CouponThirdOrder列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /couponThirdOrders/getCouponThirdOrderList [get]

export var getCouponThirdOrderList = function getCouponThirdOrderList(params) {
  return service({
    url: "/couponThirdOrders/getCouponThirdOrderList",
    method: 'get',
    params: params
  });
};